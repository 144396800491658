import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import { getBrokerData } from 'src/store/broker';
import { resendQuoteReminder } from 'src/store/planConfiguration';
import { getAccountProposalSelector } from 'src/store/proposals';

import { IssuerOfferForm } from 'src/features/issuerCoverageBuilder/IssuerOfferForm/IssuerOfferForm';
import { useMedicalIssuerBuild } from 'src/features/issuer/issuerRfpView/features/medicalPlans/useMedicalIssuerBuild';
import { useDentalIssuerBuild } from 'src/features/issuer/issuerRfpView/features/dentalPlans/useDentalIssuerBuild';
import { useVisionIssuerBuild } from 'src/features/issuer/issuerRfpView/features/visionPlans/useVisionIssuerBuild';
import { useLifeIssuerBuild } from 'src/features/issuer/issuerRfpView/features/lifePlans/useLifeIssuerBuild';
import { useDisabilityIssuerBuild } from 'src/features/issuer/issuerRfpView/features/disabilityPlans/useDisabilityIssuerBuild';
import { useAccidentIssuerBuild } from 'src/features/issuer/issuerRfpView/features/accidentPlans/useAccidentIssuerBuild';
import { useHospitalIssuerBuild } from 'src/features/issuer/issuerRfpView/features/hospitalPlans/useHospitalIssuerBuild';
import { useCriticalIssuerBuild } from 'src/features/issuer/issuerRfpView/features/criticalPlans/useCriticalIssuerBuild';

import { currencyFormatter } from 'src/constants/currency';
import { normalizeDisabilityRateBasis, normalizeRateTierType } from 'src/constants/requestProposal';
import { TOOLTIP_NAMES } from 'src/constants/tooltipNames';
import { COVERAGE_NAMES } from '../cb_constants';

import { Button, Modal, SelectedOfferCard, Status } from 'src/components';
import { OfferDetailsTable } from '../OfferDetailsTable/OfferDetailsTable';

import PurpleCircle from 'src/assets/app/purpleCircle.svg';
import CheckedPurpleCircle from 'src/assets/app/checkedPurpleCircle.svg';
import classes from 'src/sass/styles/Proposal/proposal.module.scss';

export const CardOffers = (props) => {
  const {
    build,
    type = 'proposal',
    offers,
    brokerConfig,
    fetchOffers,
    onSelectCoverageOffer,
  } = props;

  const { coverage } = build;

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params = useParams();

  const brokerData = useSelector(getBrokerData);
  const accountProposalData = useSelector(getAccountProposalSelector);

  const accountId = params?.id ? params?.id : accountProposalData?.account_id;
  const proposalId = params?.proposalId ? params?.proposalId : accountProposalData?.id;

  const isReadOnlyMode = pathname.includes('/read-only');

  const [selectedOffer, setSelectedOffer] = useState({});
  const [isOfferDetailsModal, setIsOfferDetailsModal] = useState(false);
  const [isEditConfig, setIsEditConfig] = useState(false);

  const onOpenDetailsModal = useCallback(
    (item) => () => {
      setIsOfferDetailsModal(true);
      setSelectedOffer(item);
    },
    [],
  );

  const onCloseDetailsModal = useCallback(() => {
    setIsOfferDetailsModal(false);
    setSelectedOffer({});
  }, []);

  const onClickCopyIcon = useCallback(
    (value) => () => {
      navigator.clipboard.writeText(value);
      toast('Email is copied to clipboard', { type: 'success' });
    },
    [],
  );

  const onClickResend = useCallback(
    (id) => () => {
      dispatch(resendQuoteReminder({ accountId, proposalId, contactId: id }));
    },
    [dispatch, accountId, proposalId],
  );

  const renderContentItem = useCallback((title, value) => {
    return (
      <div className={classes.ProposalContentItem}>
        <div className={classes.ProposalContentItemTitle}>{title}</div>
        <Tooltip title={value}>
          <div className={classes.ProposalContentItemSubTitle}>
            {value?.length > 6 ? `${value.slice(0, 6)}...` : value}
          </div>
        </Tooltip>
      </div>
    );
  }, []);

  const onClickEditOffer = useCallback(
    (offer) => () => {
      setSelectedOffer(offer);
      setIsEditConfig(true);
    },
    [],
  );

  const onCloseEditOfferModal = useCallback(() => {
    setSelectedOffer({});
    setIsEditConfig(false);
  }, []);

  const statuses = ['pending', 'declined', 'rejected'];
  const selectedStatuses = ['selected', 'approved'];

  const detailedOffers = [brokerConfig, selectedOffer];

  const selectedOffers = offers?.filter((item) =>
    type === 'proposal' ? selectedStatuses.includes(item.status) : item,
  );

  const { build: medicalBuild } = useMedicalIssuerBuild();
  const { build: dentalBuild } = useDentalIssuerBuild();
  const { build: visionBuild } = useVisionIssuerBuild();
  const { build: lifeBuild } = useLifeIssuerBuild();
  const { build: disabilityBuild } = useDisabilityIssuerBuild();
  const { build: accidentBuild } = useAccidentIssuerBuild();
  const { build: hospitalBuild } = useHospitalIssuerBuild();
  const { build: criticalBuild } = useCriticalIssuerBuild();

  const coverageToBuild = {
    medical: { ...medicalBuild, user_type: 'broker' },
    dental: { ...dentalBuild, user_type: 'broker' },
    vision: { ...visionBuild, user_type: 'broker' },
    life: { ...lifeBuild, user_type: 'broker' },
    disability: { ...disabilityBuild, user_type: 'broker' },
    accident: { ...accidentBuild, user_type: 'broker' },
    hospital: { ...hospitalBuild, user_type: 'broker' },
    critical_illness: { ...criticalBuild, user_type: 'broker' },
  };

  return (
    <>
      {isEditConfig && (
        <Modal type="xlarge" closeButtonType="inside" onClose={onCloseEditOfferModal}>
          <div className={classes.ConfigModalContent}>
            <div className={classes.OfferModalContentTitle}>
              {COVERAGE_NAMES[coverage]} Offer Details
            </div>
            <IssuerOfferForm
              build={coverageToBuild[coverage]}
              offers={[brokerConfig, selectedOffer]}
              planId={selectedOffer?.config_id}
              fetchOffers={fetchOffers}
            />
          </div>
        </Modal>
      )}
      {isOfferDetailsModal && (
        <Modal type="xlarge" closeButtonType="inside" onClose={onCloseDetailsModal}>
          <div className={classes.OfferModalContent}>
            <div className={classes.OfferModalContentTitle}>
              {COVERAGE_NAMES[coverage]} Plan Offer
            </div>
            <OfferDetailsTable build={build} offers={detailedOffers} type="details" />
          </div>
        </Modal>
      )}
      {type === 'proposal' &&
        offers.map((item) => (
          <div className={classes.ProposalContent} key={uuid()}>
            {!isReadOnlyMode && (
              <div className={classes.ProposalContentImage}>
                {!statuses.includes(item.status) && (
                  <>
                    {item.status === 'selected' || item.status === 'approved' ? (
                      <img
                        src={CheckedPurpleCircle}
                        alt="CheckedPurpleCircle"
                        onClick={onSelectCoverageOffer(item, 'completed')}
                      />
                    ) : (
                      <img
                        src={PurpleCircle}
                        alt="PurpleCircle"
                        onClick={onSelectCoverageOffer(item, 'selected')}
                      />
                    )}
                  </>
                )}
              </div>
            )}
            <div className={classes.ProposalContentBody}>
              {renderContentItem('Carrier Name', item.issuer_name)}

              <div className={classes.ProposalContentItem}>
                <div className={classes.ProposalContentItemTitle}>Rep Name</div>
                <Tooltip title={`${item?.author?.display_name}(${item?.author?.email})`}>
                  <div
                    className={classes.ProposalContentItemSubTitle}
                    style={{ maxWidth: 'unset' }}
                  >
                    {item?.author?.display_name?.length > 8
                      ? `${item?.author?.display_name.slice(0, 8)}...`
                      : item?.author?.display_name}

                    <i
                      className={`${classes.ProposalContentItemIcon} fa fa-clone`}
                      onClick={onClickCopyIcon(item?.author?.email)}
                    />
                  </div>
                </Tooltip>
              </div>
              {!statuses.includes(item.status) && (
                <>
                  {renderContentItem('Rate Tier Type', normalizeRateTierType(item.rate_tier_type))}
                  {coverage === 'disability' && (
                    <>
                      {renderContentItem('$ Per Unit', currencyFormatter(item.rate_per_unit))}
                      {renderContentItem(
                        'Rate Basis',
                        normalizeDisabilityRateBasis(item.rate_basis),
                      )}
                      {renderContentItem('TOTAL', currencyFormatter(item?.calculation?.total))}
                    </>
                  )}
                  {!['life', 'disability'].includes(coverage) && (
                    <>
                      {renderContentItem('TOTAL', currencyFormatter(item?.calculation?.total))}
                      {item.rate_tier_type === '3tier' && (
                        <>
                          {renderContentItem('EE', currencyFormatter(item.rate_ee))}
                          {renderContentItem('EE + 1', currencyFormatter(item.rate_ee_1))}
                          {renderContentItem('FAM', currencyFormatter(item.rate_fam))}
                        </>
                      )}

                      {item.rate_tier_type === '4tier' && (
                        <>
                          {renderContentItem('EE', currencyFormatter(item.rate_ee))}
                          {renderContentItem('EE + SP', currencyFormatter(item.rate_ee_sp))}
                          {renderContentItem('EE + CH', currencyFormatter(item.rate_ee_ch))}
                          {renderContentItem('FAM', currencyFormatter(item.rate_fam))}
                        </>
                      )}
                    </>
                  )}

                  {coverage === 'life' && (
                    <>
                      {renderContentItem(
                        'TOTAL',
                        currencyFormatter(item?.calculation?.totalTobacco),
                      )}
                      {item.is_uni_tobacco && item.rate_tier_type === '4tier' && (
                        <>
                          {renderContentItem(
                            'Employee Uni-Tobacco',
                            currencyFormatter(item.rate_ee_uni_tobacco),
                          )}
                        </>
                      )}
                      {!item.is_uni_tobacco && item.rate_tier_type === '4tier' && (
                        <>
                          {renderContentItem(
                            'Employee Tobacco',
                            currencyFormatter(item.rate_ee_tobacco),
                          )}
                          {renderContentItem(
                            'Employee Non-Tobacco',
                            currencyFormatter(item.rate_ee_non_tobacco),
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              <div className={classes.ProposalContentButtons}>
                <div className={classes.ProposalContentItem}>
                  <Status status={item?.status} />
                </div>
                {item.status === 'pending' && (
                  <>
                    <Button
                      title="Remind"
                      onClick={onClickResend(item?.author?.id)}
                      type="secondary"
                    />
                  </>
                )}
                {!statuses.includes(item.status) && (
                  <Button
                    title="Offer Details"
                    type="secondary"
                    onClick={onOpenDetailsModal(item)}
                  />
                )}
                {selectedStatuses.includes(item.status) && (
                  <Tooltip title={TOOLTIP_NAMES.DESELECT_OFFER}>
                    <i className={`${classes.EditIcon} fas fa-lock`} />
                  </Tooltip>
                )}
                {['pending', 'completed'].includes(item.status) && (
                  <i
                    className={`${classes.EditIcon} fas fa-edit`}
                    onClick={onClickEditOffer(item)}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      <SelectedOfferCard>
        <OfferDetailsTable
          build={build}
          offers={[brokerConfig, ...selectedOffers]}
          type="rfps"
          coverageType={type}
        />
      </SelectedOfferCard>
    </>
  );
};
