// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
// import { EmptyField, Loader, LoaderWrapper } from 'src/components';

// import { getIssuerDataSelector } from 'src/store/issuer';

// import {
//   getIssuerVisionPlans,
//   getIssuerVisionPlansSelecetor,
//   isLoadingIssuerVisionPlansSelector,
// } from 'src/store/visionConfiguration';

// import { VisionConfiguration } from '../../components';

// import classes from './visionPlans.module.scss';

// export const VisionPlans = (props) => {
//   const { activeTab } = props;

//   const dispatch = useDispatch();

//   const params = useParams();
//   const accountId = params?.id;
//   const proposalId = params?.proposalId;

//   const issuerData = useSelector(getIssuerDataSelector);
//   const vision = useSelector(getIssuerVisionPlansSelecetor);
//   const isLoading = useSelector(isLoadingIssuerVisionPlansSelector);

//   const issuerId = issuerData?.issuer_id;

//   useEffect(() => {
//     dispatch(getIssuerVisionPlans({ accountId, proposalId }));
//   }, [dispatch, accountId, proposalId]);

//   return isLoading ? (
//     <div className={classes.Loader}>
//       <LoaderWrapper>
//         <Loader />
//       </LoaderWrapper>
//     </div>
//   ) : vision?.length > 0 ? (
//     vision?.map((item) => (
//       <div key={item.id}>
//         <VisionConfiguration
//           id={item.id}
//           activeTab={activeTab}
//           accountId={accountId}
//           issuerId={issuerId}
//           proposalId={item.proposal_id}
//           userId={issuerData?.id}
//           brokerRequest={item}
//         />
//       </div>
//     ))
//   ) : (
//     <EmptyField title="No vision RFP created yet." />
//   );
// };

import React from 'react';

import { useVisionIssuerBuild } from './useVisionIssuerBuild';
import { IssuerCoverageBuilder } from 'src/features/issuerCoverageBuilder/IssuerCoverageBuilder';

export const VisionPlans = () => {
  const { build } = useVisionIssuerBuild();

  return <IssuerCoverageBuilder build={build} />;
};
